import type { UserRole } from '@prisma/client';
import type { AccessCode } from '~/types/accessCodes';
import type { UserAccessLevel } from '~/types/users';

const accessLevel = (code: AccessCode, role: UserRole): UserAccessLevel => {
  return access[code][role];
};

const checkAccess = (
  code: AccessCode,
  role: UserRole,
  required: UserAccessLevel
): boolean => {
  return levelMap[accessLevel(code, role)] >= levelMap[required];
};

export { accessLevel, checkAccess };

const levelMap: {
  [level in UserAccessLevel]: number;
} = {
  R: 0,
  L: 1,
  V: 2,
  E: 3,
  F: 4,
};

const access: {
  [code in AccessCode]: { [role in UserRole]: UserAccessLevel };
} = {
  // SUPPORT SPACE
  SU_GENERAL: {
    SUPPORT: 'F',
    OWNER: 'R',
    MANAGER: 'R',
    MEMBER: 'R',
    CLIENT: 'R',
    CREATOR: 'R',
  },
  // DISCOVERY SPACE
  DS_GENERAL: {
    SUPPORT: 'F',
    OWNER: 'V',
    MANAGER: 'V',
    MEMBER: 'V',
    CLIENT: 'V',
    CREATOR: 'V',
  },
  // AGENCY SPACE
  AG_ACCESS: {
    SUPPORT: 'F',
    OWNER: 'F',
    MANAGER: 'E',
    MEMBER: 'V',
    CLIENT: 'R',
    CREATOR: 'R',
  },
  AG_SETTINGS: {
    SUPPORT: 'F',
    OWNER: 'F',
    MANAGER: 'R',
    MEMBER: 'R',
    CLIENT: 'R',
    CREATOR: 'R',
  },
  AG_CLIENTS: {
    SUPPORT: 'F',
    OWNER: 'F',
    MANAGER: 'E',
    MEMBER: 'V',
    CLIENT: 'R',
    CREATOR: 'R',
  },
  AG_MEMBERS: {
    SUPPORT: 'F',
    OWNER: 'F',
    MANAGER: 'E',
    MEMBER: 'V',
    CLIENT: 'R',
    CREATOR: 'R',
  },
  AG_TAGS: {
    SUPPORT: 'F',
    OWNER: 'F',
    MANAGER: 'E',
    MEMBER: 'V',
    CLIENT: 'R',
    CREATOR: 'R',
  },
  AG_BUDGETS: {
    SUPPORT: 'F',
    OWNER: 'F',
    MANAGER: 'E',
    MEMBER: 'R',
    CLIENT: 'R',
    CREATOR: 'R',
  },
  AG_OV_ANALYTICS: {
    SUPPORT: 'F',
    OWNER: 'F',
    MANAGER: 'E',
    MEMBER: 'V',
    CLIENT: 'R',
    CREATOR: 'R',
  },
  AG_OV_CLIENTS: {
    SUPPORT: 'F',
    OWNER: 'F',
    MANAGER: 'E',
    MEMBER: 'V',
    CLIENT: 'R',
    CREATOR: 'R',
  },
  AG_OV_FINANCE: {
    SUPPORT: 'F',
    OWNER: 'F',
    MANAGER: 'E',
    MEMBER: 'R',
    CLIENT: 'R',
    CREATOR: 'R',
  },
  // CLIENT SPACE
  // General
  CL_GENERAL: {
    SUPPORT: 'F',
    OWNER: 'F',
    MANAGER: 'E',
    MEMBER: 'V',
    CLIENT: 'R',
    CREATOR: 'R',
  },
  CL_TEAM: {
    SUPPORT: 'F',
    OWNER: 'F',
    MANAGER: 'E',
    MEMBER: 'V',
    CLIENT: 'R',
    CREATOR: 'V',
  },
  CL_GUESTS: {
    SUPPORT: 'F',
    OWNER: 'F',
    MANAGER: 'E',
    MEMBER: 'V',
    CLIENT: 'V',
    CREATOR: 'R',
  },
  CL_LIST_PROJECTS: {
    SUPPORT: 'F',
    OWNER: 'F',
    MANAGER: 'E',
    MEMBER: 'V',
    CLIENT: 'V',
    CREATOR: 'R',
  },
  CL_LIST_CREATIVES: {
    SUPPORT: 'F',
    OWNER: 'F',
    MANAGER: 'E',
    MEMBER: 'V',
    CLIENT: 'V',
    CREATOR: 'L',
  },
  CL_COMMENTS: {
    SUPPORT: 'F',
    OWNER: 'F',
    MANAGER: 'E',
    MEMBER: 'E',
    CLIENT: 'V',
    CREATOR: 'L',
  },
  // Projects
  CL_PR_DETAILS: {
    SUPPORT: 'F',
    OWNER: 'F',
    MANAGER: 'E',
    MEMBER: 'V',
    CLIENT: 'V',
    CREATOR: 'R',
  },
  CL_PR_AUDIENCE: {
    SUPPORT: 'F',
    OWNER: 'F',
    MANAGER: 'E',
    MEMBER: 'E',
    CLIENT: 'V',
    CREATOR: 'R',
  },
  CL_PR_TALENTS: {
    SUPPORT: 'F',
    OWNER: 'F',
    MANAGER: 'E',
    MEMBER: 'V',
    CLIENT: 'V',
    CREATOR: 'R',
  },
  CL_PR_TALENTS_FINANCE: {
    SUPPORT: 'F',
    OWNER: 'F',
    MANAGER: 'E',
    MEMBER: 'R',
    CLIENT: 'R',
    CREATOR: 'R',
  },
  CL_PR_OUTLETS: {
    SUPPORT: 'F',
    OWNER: 'F',
    MANAGER: 'E',
    MEMBER: 'E',
    CLIENT: 'V',
    CREATOR: 'R',
  },
  CL_PR_BUDGET: {
    SUPPORT: 'F',
    OWNER: 'F',
    MANAGER: 'E',
    MEMBER: 'R',
    CLIENT: 'R',
    CREATOR: 'R',
  },
  CL_PR_COA_BW: {
    SUPPORT: 'F',
    OWNER: 'F',
    MANAGER: 'E',
    MEMBER: 'V',
    CLIENT: 'R',
    CREATOR: 'R',
  },
  CL_PR_COA_OW: {
    SUPPORT: 'F',
    OWNER: 'F',
    MANAGER: 'E',
    MEMBER: 'V',
    CLIENT: 'V',
    CREATOR: 'R',
  },
  CL_PR_BRAINSTORMING: {
    SUPPORT: 'F',
    OWNER: 'F',
    MANAGER: 'E',
    MEMBER: 'E',
    CLIENT: 'R',
    CREATOR: 'R',
  },
  CL_PR_CONT_ANALYTICS: {
    SUPPORT: 'F',
    OWNER: 'F',
    MANAGER: 'E',
    MEMBER: 'V',
    CLIENT: 'V',
    CREATOR: 'R',
  },
  CL_PR_FINANCE_ANALYTICS: {
    SUPPORT: 'F',
    OWNER: 'F',
    MANAGER: 'E',
    MEMBER: 'R',
    CLIENT: 'R',
    CREATOR: 'R',
  },
  // Creatives
  CL_CREATIVE_ACCESS: {
    SUPPORT: 'F',
    OWNER: 'F',
    MANAGER: 'E',
    MEMBER: 'E',
    CLIENT: 'V',
    CREATOR: 'E',
  },
  CL_CR_DETAILS: {
    SUPPORT: 'F',
    OWNER: 'F',
    MANAGER: 'E',
    MEMBER: 'E',
    CLIENT: 'V',
    CREATOR: 'V',
  },
  CL_CR_TALENTS: {
    SUPPORT: 'F',
    OWNER: 'F',
    MANAGER: 'E',
    MEMBER: 'E',
    CLIENT: 'V',
    CREATOR: 'V',
  },
  CL_CR_OUTLETS: {
    SUPPORT: 'F',
    OWNER: 'F',
    MANAGER: 'E',
    MEMBER: 'E',
    CLIENT: 'V',
    CREATOR: 'V',
  },
  CL_CR_SCRIPT: {
    SUPPORT: 'F',
    OWNER: 'F',
    MANAGER: 'E',
    MEMBER: 'E',
    CLIENT: 'V',
    CREATOR: 'E',
  },
  CL_CR_PRODUCTION: {
    SUPPORT: 'F',
    OWNER: 'F',
    MANAGER: 'E',
    MEMBER: 'E',
    CLIENT: 'V',
    CREATOR: 'E',
  },
  CL_CR_PUBLISHING: {
    SUPPORT: 'F',
    OWNER: 'F',
    MANAGER: 'E',
    MEMBER: 'E',
    CLIENT: 'V',
    CREATOR: 'E',
  },
};
